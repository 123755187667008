<template>
	<div class='a-sidebar-items'>
		<div class="row" v-if='title'>
			<div class="col">
				<p class='a-sidebar-title'>{{title}}</p>
			</div>
			<div class="col-auto" v-if='reset'>
				<p class='app-link green--text' @click='$emit("reset")'>{{$t("account.buttons.cast")}}</p>
			</div>
		</div>
		<div class='a-sidebar-scroll'>
			<div class="row a-sidebar-row" v-if='all'>
				<div class="col">
					<v-checkbox
						:name='all.id'
						:label='all.name'
						v-model='all_item'
						@change='$emit("reset")'
					/>
				</div>
			</div>
            <template v-for="(item, i) in _items">
                <div class="row a-sidebar-row" :key='i' v-if="item.name">
                    <div class="col">
                        <v-checkbox
                            :name='item.id'
                            :label='item.name'
                            :disabled='item.disabled'
                            v-model='item.active'
                            @change='$emit("update", items)'
                        />
                    </div>
                    <div class="col-auto" v-if='item.total'>
                        <p class='a-sidebar-text-total'>{{item.total}}</p>
                    </div>
                </div>
            </template>

		</div>
		<div class='a-sidebar-link-more green--text tablet-show' @click='count = "all"' v-if='items.length > count'>
			<p class='app-link'>{{$t("account.buttons.show_more")}}</p>
			<v-icon icon='arrow-down' :size='10' />
		</div>
		<div class='a-sidebar-link-more green--text tablet-show' @click='count = 8' v-if='count === "all"'>
			<p class='app-link'>{{$t("account.buttons.collapse_list")}}</p>
			<v-icon icon='arrow-down' :size='10' style='transform: rotate(-180deg)' />
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			count: 8,
			all_item: true
		}),
		props: ["title", "items", "reset", "all"],
		computed: {
			_items() {
				return this.count === 'all' ? this.items : this.items.slice(0, this.count)
			}
		},
		created() {
			this.count = window.innerWidth <= 960 ? 8 : 'all';

			window.addEventListener(`resize`, () => {
				this.count = window.innerWidth <= 960 ? 8 : 'all';
			}, false);
		},
		watch: {
			items() {
				this.all_item = !this.items.some(el => el.active);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-sidebar {
		&-items {
			margin-bottom: 40px * .8;
			@include minw($grid-breakpoints-md) {
				margin-bottom: 40px;
			}
		}
		&-row {
			&:not(:last-child) {
				margin-bottom: 16px * .8;
				@include minw($grid-breakpoints-md) {
					margin-bottom: 16px;
				}
			}
		}
		&-text {
			&-total {
				font-size: 16px * .9;
				font-weight: 400;
				color: rgba($black, .4);
				@include minw($grid-breakpoints-md) {
					font-size: 16px;
				}
			}
		}
		&-link-more {
			display: flex;
			align-items: center;
			margin-top: 20px;
			.app-link {
				font-size: 15px;
			}
			.app-icon {
				margin-left: 10px;
			}
		}
	}
</style>
