<template>
	<date-picker
		:value="value"
		@change='$emit("change", $event)'
		:placeholder='placeholder ? placeholder : $t("account.form.placeholder.choose_a_date")'
		:range='range'
		format="DD/MM/YYYY"
		:lang="$route.params.lang ? $route.params.lang : 'uk'"
		:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
		range-separator=' — '
		:append-to-body='false'
		class='a-booking-form-datepicker a-booking-form-datepicker-complex-route'
		:class='{"mx-datepicker-right": right, "invalid": errors}'
		:editable='false'
		value-type='timestamp'
		:partial-update='true'
		:open.sync="open"
	>
		<template v-slot:header>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-auto">
					<div class='mx-datepicker-header-close' @click="open = false">
						<v-icon icon="cross" size="20"></v-icon>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:content>
			<div class='mx-range-wrapper'>
				<div v-for='(item, i) in calendars' :key='i'>
					<calendar-panel
						:value="innerValue"
						:get-classes="getClasses"
						:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
						:calendar="item"
						@update:calendar="handleUpdateCalendar"
						@select="handleSelect"
					/>
				</div>
			</div>
		</template>
	</date-picker>
</template>

<script>
	import { addMonths } from "date-fns";
	import DatePicker from "vue2-datepicker";

	const { CalendarPanel } = DatePicker;

	export default {
		data: () => ({
			open: false,
			innerValue: [],
			hoveredValue: null,
			calendars: [],
			size: 2,
			setTimeout: null
		}),
		props: {
			value: {
				type: [String, Number, Array]
			},
			range: {
				type: Boolean,
				default: false
			},
			right: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String
			},
			errors: {
				type: [Boolean, Array],
				default: false
			},
			directions: Array
		},
		components: {
			CalendarPanel
		},
		created() {
			this.innerValue = this.range ? (this.value ? this.value.map(el => new Date(el)) : []) : (this.value ? new Date(this.value) : null);
		},
		methods: {
			handleUpdateCalendar(date) {
				this.calendars = [];
				for (let i = 0; i < this.size; i++) {
					this.calendars.push(addMonths(date, i))
				}

				this.size = window.innerWidth <= 600 ? this.size + 1 : 2;
			},
			getClasses(cellDate, currentDates, classes) {
				const result = [];
				if (/disabled|not-current-month/.test(classes)) {
					return result;
				}
				if (this.directions.some(v => v.date && new Date(v.date).getTime() === cellDate.getTime())) {
					result.push("active");
				}
				return result;
			},
			handleSelect(cellDate) {
				this.$emit("change", cellDate.getTime());
				this.open = false;
			}
		},
		watch: {
			value() {
				this.innerValue = this.range ? (this.value ? this.value.map(el => new Date(el)) : []) : (this.value ? new Date(this.value) : null);
			},
			open() {
				if(this.open) {
					this.size = window.innerWidth <= 600 ? 12 : 2;

					this.setTimeout = setTimeout(() => {
						let list = document.querySelector('.a-booking-form-datepicker-complex-route .mx-datepicker-main');
						let self = this;

						if(list) list.addEventListener('scroll', function() {
							if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
								self.handleUpdateCalendar(new Date());
							}
						});

						let currentDate = this.range ? (this.value ? new Date(this.value[0]) : new Date()) : (this.value ? new Date(this.value) : new Date());
						this.handleUpdateCalendar(currentDate);
					}, 150)
				} else {
					clearTimeout(this.setTimeout);
				}
			}
		}
	}
</script>
