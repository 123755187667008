<template>
	<a-dropdown :invalid='errors' :dropdown-right='dropdownRight'>
		<template v-slot:field>
			<div class="a-select__field">
				<div class="selected" v-if='title'> {{ title }} </div>
				<div class="placeholder" v-else> {{ placeholder ? placeholder : $t("account.booking.content.choose") }} </div>
			</div>
			<div class="a-select__append-inner" v-if='appendIcon'>
				<v-icon :icon="appendIcon" size="14" />
			</div>
		</template>
		<template v-slot:dropdown="{ closeSelect }">
			<div class='a-people' :class='{"a-people-compact": compact}'>
				<div v-for='(item, i) in rooms' :key='i'>
					<div class="row a-people-header" v-if='isRooms'>
						<div class="col">
							<p class='a-people-title'>{{$t("account.booking.content.room")}} {{i + 1}}</p>
						</div>
						<div class="col-auto" v-if='!isSingleRoom'>
							<p class='a-people-btn-remove' @click='removeRoom(i)'>{{$t("account.buttons.remove")}}</p>
						</div>
					</div>
					<div class="row a-people-row" :class='{"a-people-row-compact": compact}'>
						<div class="col a-people-col">
							<div class='a-people-item'>
								<a-stepper
									:title='$t("account.booking.content.adults") + ` ${isInfant ? "(18+)" : ""}`'
									:count='item.adults'
									:minCount='1'
									@change='updateAdults($event, i)'
									:descr='!compact && !isInfant ? $t("account.booking.content.descr_adult") : null'
								/>
							</div>
						</div>
						<div class="col a-people-col">
							<div class='a-people-item'>
								<a-stepper
									:title='$t("account.booking.content.children") + ` ${isInfant ? `(${$t("account.to")} 18)` : ""}`'
									:count='item.childrenCount'
									@change='updateChildrenCount($event, i)'
									:descr='!compact && !isInfant ? $t("account.booking.content.descr_child") : null'
								/>
							</div>
						</div>
						<div class="col a-people-col" v-if='isInfant'>
							<div class='a-people-item'>
								<a-stepper
									:title='$t("account.booking.content.descr_infant")'
									:count='item.infant'
									@change='updateInfant($event, i)'
								/>
							</div>
						</div>
					</div>

					<div class='row a-people-row a-people-row-children'>
						<div class="col-auto a-people-col a-people-col-children"
							v-for='(child, index) in item.children' :key='index'
						>
							<div class='a-people-item-children'>
								<p class='a-people-label'>{{$t("account.booking.content.age_child")}}:</p>
								<a-select
									:options='items_age'
									placeholder='-'
									xxsmall
									v-model='item.children[index]'
									@input='$event => updateChildren($event, i, index)'
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if='isRooms && !isSingleRoom'>
					<div class="col-auto ml-auto">
						<span class='a-people-btn-add' @click='addRoom'>
							<v-icon icon='plus' size='10'></v-icon>
							{{$t("account.booking.content.add_room")}}
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-auto ml-auto">
						<span class='app-link green--text' @click.stop='closeSelect()'>{{$t("account.booking.content.done")}}</span>
					</div>
				</div>
			</div>
		</template>
	</a-dropdown>
</template>

<script>
	import { items_age } from '@/helpers/items';
	import _ from 'lodash';

	export default {
		data: () => ({
			items_age,
			rooms: []
		}),
		props: {
			placeholder: {
				type: String
			},
			"append-icon": {
				type: [String, Boolean],
				default: 'arrow-down'
			},
			errors: [Boolean],
			isRooms: {
				type: Boolean,
				default: false
			},
			compact: {
				type: Boolean,
				default: false
			},
			isInfant: {
				type: Boolean,
				default: false
			},
			"dropdown-right": {
				type: Boolean,
				default: false
			},
			isSingleRoom: {
				type: Boolean,
				default: false
			},
			tourists: Array,
			adults: Number,
			childrenCount: Number,
			children: Array
		},
		computed: {
			title() {
				if(!this.rooms.length) return;

				let totalTourist = _.sumBy(this.rooms, "adults") + _.sumBy(this.rooms, "childrenCount");

				if(this.isRooms) {
					return `${totalTourist} ${this.$helpers.declOfNum(totalTourist, this.$t('account.declOfNum.tourists'))}, ${this.rooms.length} ${this.$helpers.declOfNum(this.rooms.length, this.$t('account.declOfNum.rooms'))}`
				} else if(this.isInfant) {
					let _a = this.rooms[0].adults ? `${this.rooms[0].adults} ${this.$helpers.declOfNum(this.rooms[0].adults, this.$t('account.declOfNum.adults'))}` : '';
					let _c = this.rooms[0].childrenCount ? `, ${this.rooms[0].childrenCount} ${this.$helpers.declOfNum(this.rooms[0].childrenCount, this.$t('account.declOfNum.children'))}` : '';
					let _b = this.rooms[0].infant ? `, ${this.rooms[0].infant} ${this.$helpers.declOfNum(this.rooms[0].infant, this.$t('account.declOfNum.infant'))}` : '';

					return `${_a}${_c}${_b}`;
				} else {
					return `${this.$t("account.booking.content.tourists")}: ${this.rooms[0].adults} — ${this.rooms[0].childrenCount}`
				}
			},
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			}
		},
		created() {
			if(!this.isRooms || this.isSingleRoom) this.addRoom();

			if(this.source) {
				this.rooms[0].adults = this.source.adults;
				this.rooms[0].children = this.source.children;
				this.rooms[0].childrenCount = this.source.childrenCount;
				this.rooms[0].infant = this.source.infant;
				this.updatePeople();
			}
		},
		methods: {
			updatePeople() {
				this.$emit('update-people', (this.compact || this.isSingleRoom) ? this.rooms[0] : this.rooms);
			},
			addRoom() {
				let isInfant = this.isInfant ? { infant: 0 } : null
				this.rooms.push({adults: this.adults ?? 1, childrenCount: this.childrenCount ?? 0, children: this.children ?? [], ...isInfant});
				this.updatePeople();
			},
			removeRoom(i) {
				this.$delete(this.rooms, i);
				this.updatePeople();
			},
			updateAdults(adults, i) {
				this.rooms[i].adults = adults;
				this.updatePeople();
			},
			updateChildrenCount(childrenCount, i, age) {
				if(!this.isInfant) this.rooms[i].childrenCount > childrenCount ? this.rooms[i].children.pop() : this.rooms[i].children.push(age ?? 1);
				this.rooms[i].childrenCount = childrenCount;
				this.updatePeople();
			},
			updateChildren($event, i, index) {
				this.rooms[i].children[index] = $event;
				this.updatePeople();
			},
			updateInfant(infant, i) {
				this.rooms[i].infant = infant;
				this.updatePeople();
			}
		},
		watch: {
			tourists() {
				this.rooms[0].adults = this.tourists.length === 0 ? 1 : this.tourists.filter(el => el.age >= 16).length;
				this.rooms[0].childrenCount = this.tourists.length === 0 ? 0 : this.tourists.filter(el => el.age < 16).length;
				this.rooms[0].children = [];
				this.tourists.filter(el => el.age < 16).map((tourist, index) => {
					this.rooms[0].children[index] = tourist.age
				})
				this.updatePeople();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-people {
		padding: 25px 30px;
		@include minw( $grid-breakpoints-xs ) {
			width: 440px;
		}
		&-compact {
			@include minw( $grid-breakpoints-xs ) {
				width: 235px;
			}
		}
		&-header {
			margin-bottom: 15px;
		}
		&-item {
			margin-bottom: 25px;
			@include minw( $grid-breakpoints-xs ) {
				max-width: 175px;
			}
			&-children {
				margin-bottom: 25px;
				@include minw( $grid-breakpoints-xs ) {
					width: 72px;
				}
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 700;
		}
		&-btn {
			&-add {
				display: flex;
				align-items: center;
				font-size: 15px;
				font-weight: 700;
				color: $green-persian;
				cursor: pointer;
				transition: color $transition;
				margin-bottom: 20px;
				&:hover {
					color: $green-dark;
				}
				.app-icon {
					margin-right: 5px;
					margin-top: 5px;
				}
			}
			&-remove {
				font-size: 16px;
				font-weight: 700;
				color: $green-persian;
				cursor: pointer;
				transition: color $transition;
				&:hover {
					color: $green-dark;
				}
			}
		}
		&-label {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 5px;
		}
		&-row {
			margin-left: -15px;
			margin-right: -15px;
			&-compact {
				flex-direction: column;
			}
			&:not(.a-people-row-children) {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					flex-direction: column;
				}
			}
		}
		&-col {
			padding-left: 15px;
			padding-right: 15px;
			&-children {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			.ml-auto {
				margin-right: auto;
			}
		}
	}
</style>
