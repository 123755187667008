<template>
	<div>
		<div class='a-booking-form-direction'>
			<div class='row'>
				<div class="col-auto" v-for="item in items_types" :key='item.id'>
					<span class='a-booking-form-direction-link'
						:class='{"active": item.id === form.type}'
						@click='item.id === form.type ? false : form = {
							...form,
							type: item.id,
							directions: form.directions.slice(0, 1)
						}, changeTypes()'
					>{{ item.name }}</span>
				</div>
			</div>
		</div>

		<form>
			<div class='a-booking-form-search-group-multi' v-for='(item, i) in form.directions' :key='i'>
				<div class='a-booking-form-search-group'>
					<div class='a-booking-form-search-departure'>
						<select-terminals
							:placeholder='$t("account.booking.content.departure")'
							prepend-icon='departure'
							v-model='item.terminalFrom'
							@input='form = {...form, directions: [...form.directions] }'
							:errors='!item.terminalFrom && errors[`directions.${i}.terminalFrom`]'
						/>
					</div>

					<div class='a-booking-form-search-button' @click='changeDirection(item, i)'>
						<v-icon icon='reverse' size='16'/>
					</div>

					<div class='a-booking-form-search-arrivale'>
						<select-terminals
							:placeholder='$t("account.booking.content.arrivale")'
							prepend-icon='arrivale'
							v-model='item.terminalTo'
							@input='form = {...form, directions: [...form.directions] }'
							:errors='!item.terminalTo && errors[`directions.${i}.terminalTo`]'
						/>
					</div>
				</div>
				<div class="a-booking-form-search-datepicker" v-if='form.type == "complex_route"'>
					<a-date-picker-complex-route
						v-model='item.date'
						@change='changeDate($event, i)'
						:errors='!item.date && errors[`directions.${i}.dateFrom`]'
						right
						:directions='form.directions'
					/>
				</div>
				<div class='a-booking-form-search-group-btn-close tablet-hide' v-if='form.type == "complex_route" && form.directions.length > 1'
					@click='$delete(form.directions, i)'
				>
					<v-icon icon='cross' size='12' />
				</div>
			</div>

			<div class="row" v-if='form.type == "complex_route"'>
				<div class="col">
					<div v-if='form.type == "complex_route"'>
						<p class='app-link green--text a-booking-form-multi-button'
							@click='form = {
								...form,
								directions: [...form.directions, {
									date: $_.last(form.directions).dateFrom ? $moment($_.last(form.directions).dateFrom).valueOf() : null,
									dateFrom: $_.last(form.directions).dateFrom,
									terminalFrom: null,
									terminalTo: null
								}]
							}'
						>{{$t('account.booking.content.add_direction')}}</p>
					</div>
				</div>
				<div class="col-auto tablet-show" v-if='form.directions.length > 1'>
					<p class='app-link green--text a-booking-form-multi-button'
						@click='$delete(form.directions, form.directions.length - 1)'
					>{{$t('account.buttons.remove')}}</p>
				</div>
			</div>

			<div class='a-booking-form-row'>
				<div class="a-booking-form-col" v-if='form.type != "complex_route"'>
					<a-date-picker
						v-model='form.directions[0].date'
						@change='changeDate($event, 0)'
						:errors='!form.directions[0].date && errors[`directions.0.dateFrom`]'
						:range='form.type === "both"'
						class='mobile-hide'
					/>
					<a-date-picker-mobile
						v-model='form.directions[0].date'
						@change='changeDate($event, 0)'
						:errors='!form.directions[0].date && errors[`directions.0.dateFrom`]'
						:range='form.type === "both"'
						class='mobile-show'
					/>
				</div>
				<div class="a-booking-form-col" :class='{"a-booking-form-multi-airlines": form.type == "complex_route" && edit}'>
					<a-select
						:placeholder='$t("account.booking.content.choose")'
						:options='items_airClass'
						v-model='form.airClass'
						@input='form = {...form, airClass: form.airClass }'
						:errors='!form.airClass && errors.airClass'
					/>
				</div>
				<template v-if='!edit'>
					<div class="a-booking-form-col" :class='{"a-booking-form-multi-airlines": form.type == "complex_route"}'>
						<a-autocomplete
							:placeholder='$t("account.booking.content.choose_airlines")'
							v-model='airCompany'
							@input='form.airCompanies = airCompany.length ? [airCompany] : []'
							:requestName='getAutocompleteAirCompany'
							item-value='code'
							actionInitial
							append-icon='arrow-down'
						/>
					</div>
					<div class="a-booking-form-col" :style='form.tourists && form.tourists.length ? "pointer-events: none; opacity: .5": ""'>
						<select-people
							:adults='form.adults'
							:childrenCount='form.childrenCount'
							:children='form.children'
							:placeholder='$t("account.booking.content.count_tourist")'
							@update-people='$event => form = {...form, ...$event}'
							compact
							:errors='!form.adults && errors.adults'
							:tourists='form.tourists'
						/>
					</div>
				</template>
			</div>

			<template v-if='!edit'>
				<div class='a-booking-form-search-checkboxs'>
					<div class='row'>
						<div class="col-auto" v-for="item in items_checkbox" :key='item.id'>
							<v-checkbox
								:name='item.id'
								:label='item.name'
								v-model='form[item.id]'
								@change='form = {...form, [item.id]: form[item.id] }'
							/>
						</div>
					</div>
				</div>

				<select-tourist
					:initialTourists='form.tourists'
					@tourists='form.tourists = $event'
					@add-tourist='form.travelersIds = $event'
					@remove-tourist='form.travelersIds.splice($event, 1)'
				/>
			</template>

			<div class='row a-booking-form-action'>
				<div class="col">
					<template v-if='!edit'>
						<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
					</template>
				</div>
				<div class="col-auto">
					<v-button :small='!edit' :xxsmall='edit' color='green white--text' @click='flightSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SelectTerminals from "@/views/account/home/components/SelectTerminals";
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import ADatePickerComplexRoute from '@/views/account/home/components/ADatePickerComplexRoute';
	import { getAutocompleteAirCompany, flightSearch } from '@/services/request';
	import { items_airClass } from '@/helpers/items';

	export default {
		data: () => ({
			open: false,
			form: {
				type: "both",
				directions: [
					{
						dateFrom: null,
						dateTo: null,
						terminalFrom: null,
						terminalTo: null
					}
				],
				airClass: null,
				adults: 1,
				childrenCount: 0,
				children: [],
				onlyDirectFlights: false,
				onlyWithBaggage: false,
				onlyRefundable: false,
				onlyTourPackages: false,
				oneDocking: false,
				travelersIds: [],
				airCompanies: [],
				tourists: []
			},
			airCompany: {},
			loading: false,
			errors: {},
			tripName: null
		}),
		props: ["edit", "searchData", "initialTripName"],
		components: {
			SelectTerminals,
			SelectPeople,
			SelectTourist,
			ADatePickerComplexRoute
		},
		computed: {
			items_airClass() {
				return items_airClass()
			},
			items_types() {
				return [
					{id: "both", name: this.$t("account.booking.content.directions.both")},
					{id: "oneDirection", name: this.$t("account.booking.content.directions.one")},
					{id: "complex_route", name: this.$t("account.booking.content.directions.multi")}
				]
			},
			items_checkbox() {
				return [
					{id: "onlyDirectFlights", name: this.$t("account.booking.content.checkbox.only_direct_flights")},
					{id: "onlyWithBaggage", name: this.$t("account.booking.content.checkbox.only_with_luggage")},
					// {id: "onlyRefundable", name: this.$t("account.booking.content.checkbox.return_rate")},
					{id: "onlyTourPackages", name: this.$t("account.booking.content.checkbox.under_the_tour_package")},
					{id: "oneDocking", name: this.$t("account.booking.content.checkbox.one_docking")}
				]
			},
			local_search_data() {
				return localStorage.getItem('local_search_data') ? JSON.parse(localStorage.getItem('local_search_data')) : {};
			}
		},
		created() {
			if(this.local_search_data?.flights) {
				this.form = {...this.form, ...this.local_search_data.flights};
			}
			// this.form.airClass = this.items_airClass[0].id
			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
			} else if(this.searchData) {
				this.form = {...this.form, ...this.searchData};
			}
			this.tripName = this.$route.query?.tripName ?? this.initialTripName;

			if(this.form.tourists?.length === 0 && !this.edit) {
				this.form.travelersIds = [];
			}

			this.form.childrenCount = this.form.children.length

			this.form.airCompanies = [];
		},
		methods: {
			getAutocompleteAirCompany,
			flightSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
				let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				flightSearch({...this.form, ...orderId}).then(res => {
					if(this.$route.params.storageId !== res.data.storageId) {
                        let routeData = {
                            name: "flights-search",
                            params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
                            query: {source: JSON.stringify(this.form), tripName: this.tripName}
                        };
                        // this.edit ? window.open(this.$router.resolve(routeData).href, '_blank') : this.$router.push(routeData);
                        this.$router.push(routeData);
					}
                    this.$emit("update");
                    localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, flights: this.form}));
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading = false)
			},
			changeTypes() {
				if(!this.form.directions[0].dateFrom) return;

				const timestamp = this.$moment(this.form.directions[0].dateFrom).valueOf();
				if(this.form.type === 'both') {
					this.form.directions[0].date = [ timestamp, timestamp ];
					this.form.directions[0].dateFrom = this.$moment(this.form.directions[0].dateFrom).format('YYYY-MM-DD');
					this.form.directions[0].dateTo = this.$moment(this.form.directions[0].dateFrom).format('YYYY-MM-DD');
				} else {
					delete this.form.directions[0].dateTo;
					this.form.directions[0].dateFrom = this.$moment(this.form.directions[0].dateFrom).format('YYYY-MM-DD');
					this.form.directions[0].date = timestamp;
				}
			},
			changeDirection(item, i) {
				let terminalFrom = this.form.directions[i].terminalFrom;
				let terminalTo = this.form.directions[i].terminalTo;

				if(typeof this.form.directions[i].terminalFrom === "object" || typeof this.form.directions[i].terminalTo === "object") {
					this.form.directions[i].terminalTo = terminalFrom;
					this.form.directions[i].terminalFrom = terminalTo;

					this.form = {...this.form, directions: [...this.form.directions] };
				}
			},
			changeDate($event, i) {

				if(this.form.type === 'both') {
					this.form.directions[i].date = $event[0] ? $event : null;
					this.form.directions[i].dateFrom = this.$moment($event[0]).format('YYYY-MM-DD')
					this.form.directions[i].dateTo = this.$moment($event[1]).format('YYYY-MM-DD')
				} else {
					this.form.directions[i].date = $event;
					delete this.form.directions[i].dateTo
					this.form.directions[i].dateFrom = $event ? this.$moment($event).format('YYYY-MM-DD') : null
				}

				this.form = {...this.form, directions: [...this.form.directions] };
			}
		},
		watch: {
			"form.tourists"() {
				localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, flights: this.form}));
			}
		}
	}
</script>
