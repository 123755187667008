<template>
	<div>
		<div class='a-booking-form-row align-items-center a-booking-form-search-tourists'>
			<a-tourist-list
				:items='tourists'
				isDelete
				@remove-tourist="$event => removeTourist($event)"
				class='a-tourist-list-contents'
			/>
			<div class="a-booking-form-col" v-if='tourists.length <= 7'>
				<a-select-search
					v-model='innerValue'
					@input='changeInput($event)'
					@blur='changeBlur'
					:placeholder='$t("account.booking.content.add_tourist")'
					prepend-icon='user'
					:prepend-icon-size='14'
					:loading='loading'
					:errors='errors'
					@openSelect='innerValue.length === 0 && actionInitial ? changeInput(innerValue) : false'
					@input-clear="clearValue(), innerValue.length === 0 && actionInitial ? changeInput(innerValue) : false"
					outline
					class='a-dropdown-hidden a-select-search-tourist'
				>
					<template v-slot:count>
						<p class='a-select-count-text' style='margin-right: 0; margin-left: 16px' v-if='tourists.length'>{{tourists.length}}/8</p>
					</template>
					<template v-slot:dropdown="{ closeSelect }">
						<div class='a-select-search__dropdown--scroll'>
							<ul class='a-select-search__dropdown--list'>
								<li class='a-select-search__dropdown--item' v-for='(item, i) in items' :key='i' @click='changeTourist(item), closeSelect()'>
									<a-user
										:user='item'
										:subTitle='item.email'
										isLarge
									/>
								</li>
							</ul>
						</div>
						<div class='divider'></div>
						<div class='a-select-search__dropdown--footer'>
							<p class='a-select-search__dropdown--text'>{{$t("account.booking.content.did_not_find_the_tourist")}}</p>
							<span class='app-link green--text' @click='addTourist = true'>{{$t("account.booking.content.register_a_new_tourist")}}</span>
						</div>
					</template>
				</a-select-search>
			</div>
		</div>

		<form-tourist v-model='addTourist' />

	</div>
</template>

<script>
	import { getAutocompleteTourists } from '@/services/request';
	import FormTourist from '@/views/account/tourists/FormTourist';
	import ATouristList from '@/components/account/ATouristList';

	export default {
		data: () => ({
			addTourist: false,
			innerValue: '',
			loading: false,
			initialItems: [],
			page: 1,
			size: 20,
			items: [],
			tourists: []
		}),
		props: {
			"item-value": {
				type: String,
				default: 'id'
			},
			"item-name": {
				type: String,
				default: 'name'
			},
			value: [String, Object],
			placeholder: String,
			"prepend-icon": {
				type: [String, Boolean],
				default: false
			},
			"append-icon": {
				type: [String, Boolean],
				default: false
			},
			errors: [Boolean, Array],
			actionInitial: {
				type: Boolean,
				default: true
			},
			initialTourists: {
				type: [Array, Boolean],
				default: false
			}
		},
		components: {
			FormTourist,
			ATouristList
		},
		created() {
			this.initialInnerValue();
			if(this.initialTourists) {
				this.tourists = this.initialTourists;
			}
		},
		methods: {
			changeInput(item, initial) {
				if (this.request) this.request.cancel();
				const axiosSource = this.$http.CancelToken.source();
				this.request = { cancel: axiosSource.cancel };

				this.page = 1;
				if(item.length || item.length === 0 && this.actionInitial) {
					this.loading = true
					setTimeout(() => {
						getAutocompleteTourists({search: item}, { cancelToken: axiosSource.token })
							.then(res => {
								this.initialItems = res.data.filter(el => !this.tourists.find(tourist => tourist.id === el.id));
								initial ? this.initialInnerValue() : false
							})
							.catch(err => !this.$http.isCancel(err) )
							.finally(() => this.loading = false)
					}, 300)
				} else {
					this.initialItems = [];
				}
			},
			changeBlur() {
				if(this.value && this.value[this.itemValue]) {
					this.innerValue = this.value[this.itemName]
				} else if(this.innerValue && this.initialItems.length) {
					this.innerValue = this.initialItems[0][this.itemName]
					this.$emit('input', this.initialItems[0]);
				} else if(this.innerValue && this.initialItems.length === 0) {
					this.clearValue();
				}
			},
			update(item) {
				this.$emit('input', item);
				this.innerValue = item[this.itemName];
			},
			loadMore() {
				this.page = this.page + 1;
				this.items = this.initialItems.slice(0, this.size * this.page);
			},
			clearValue() {
				this.innerValue = '';
				this.$emit('input', '');
			},
			hasSlot(name = 'default') {
				return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
			},
			initialInnerValue() {
				this.innerValue = this.value ? this.value[this.itemName] : '';
			},
			changeTourist($event) {
				this.tourists = [...this.tourists, $event]
				this.$emit("tourists", this.tourists);
				this.$emit("add-tourist", this.tourists.map(el => el.id));
			},
			removeTourist(index) {
				this.tourists.splice(index, 1);
				this.$emit("tourists", this.tourists);
				this.$emit("remove-tourist", index);
			}
		},
		watch: {
			value() {
				this.initialInnerValue();
			},
			initialItems() {
				let list = document.querySelector('.a-select__dropdown');
				let self = this;

				if(list) list.addEventListener('scroll', function() {
					if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
						self.loadMore();
					}
				});

				this.loadMore();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-select-search__dropdown {
		&--text {
			font-size: 14px;
			font-weight: 300;
			line-height: 1.5;
		}
		&--scroll {
			overflow: auto;
			max-height: 140px;
			@include minw( $grid-breakpoints-xs ) {
				max-height: 180px;
			}
		}
		&--footer {
			padding: 20px 25px 25px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				text-align: center;
			}
		}
	}
	.a-dropdown-hidden {
		.a-dropdown__body {
			overflow: hidden;
		}
	}
</style>
