<template>
	<div class='a-tourist-list'>
		<div class="a-tourist-list-col" v-for='(item, i) in items' :key='i'>
			<a-user
				:user='item'
				:index="i"
				:subTitle="`${item.type === 'children' ? (item.age === 0 ? $t('account.declOfNum.up_to_1_year') : `${item.age} ${$helpers.declOfNum(item.age, $t('account.declOfNum.years'))}`) : ''}`"
				isBorder
			>
				<template v-slot:action>
					<div class='a-user--action ml-auto' v-if='isDelete'>
						<div class='a-user--action--btn' @click='$emit("remove-tourist", i)'>
							<v-icon icon='cross' size='12' />
						</div>
					</div>
				</template>
			</a-user>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			items: {
				type: Array,
				default: () => []
			},
			isDelete: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-tourist-list {
		display: contents;
		&:not(.a-tourist-list-contents) {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
		&-col {
			padding-right: 5px;
			padding-left: 5px;
			margin-bottom: 16px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}

</style>
